// See styleguide: Into-Text

p.bew-intro {
  color: rgba(0, 0, 0, 1);
  font-style: normal;
  font-weight: 300;
  margin-bottom: 18px;
  margin-top: -4px;
  max-width: 800px;
  font-size: 18px;
  line-height: 28px;
}

@media screen and (max-width: 767px) {
  p.bew-intro {
    font-size: 16px;
    line-height: 23px;
  }
}

// See styleguide: Notice

span.bew-notice {
  display: block;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 125%;
  color: rgba(0, 0, 0, 1);
  background-color: rgba(255, 255, 255, 1);
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 0;
}

// See styleguide: Placeholder

span.bew-placeholder {
  display: block;
  color: rgba(112, 112, 112, 1);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 125%;
  margin-bottom: 12px;
  padding: 0;
}
