// Style-guide: See "Label before headline".

.bew-label-before-headline {
  display: block;
  color: rgba(112, 112, 112, 1);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.01rem;
  word-spacing: 0.025rem;
  line-height: 125%;
  margin-bottom: 12px;
  padding: 0;
}

@media screen and (max-width: 767px) {
  .bew-label-before-headline {
    font-size: 13px;
    line-height: 17px;
  }
}
