// Style-Guide: See Step Box. Use this if you only want to display the number.

div.bew-step-box-number {
  text-align: center;
  font-size: 65px;
  line-height: 74px;
  letter-spacing: 0.02rem;
  word-spacing: 0.04rem;
  font-style: normal;
  font-weight: 500;
  background-color: rgba(235, 211, 174, 1);
  padding-top: 6px;
  width: 85px;
  height: 85px;
  display: table-cell;
  vertical-align: top;
}

// Style-Guide: See Step Box Text + Number. Use this if you want to display a number and a text.

div.bew-step-box {
  max-width: 800px;
  background-color: rgba(250, 241, 227, 1);
  width: 100%;
  display: table;
}

div.bew-step-box-content-number {
  text-align: center;
  font-size: 65px;
  line-height: 74px;
  letter-spacing: 0.02rem;
  word-spacing: 0.04rem;
  font-style: normal;
  font-weight: 500;
  background-color: rgba(235, 211, 174, 1);
  padding-top: 6px;
  width: 85px;
  height: 85px;
  display: table-cell;
  vertical-align: top;
}

// note: this style is not in the style-guide (but it's almost required if the number is larger). This allows displaying
// larger numbers, such as 2 digits ("12") or something like "1.1" or "1a".
div.bew-step-box-content-number-small-font {
  text-align: center;
  font-size: 24px;
  line-height: 74px;
  letter-spacing: 0.02rem;
  word-spacing: 0.04rem;
  font-style: normal;
  font-weight: 500;
  background-color: rgba(235, 211, 174, 1);
  padding-top: 6px;
  width: 85px;
  height: 85px;
  display: table-cell;
  vertical-align: top;
}

div.bew-step-box-content-text {
  display: table-cell;
  vertical-align: middle;
  background-color: rgba(250, 241, 227, 1);
  font-size: 24px;
  line-height: 28px;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0.01rem;
  word-spacing: 0.025rem;
  color: rgba(0, 0, 0, 1);
  padding: 25px;
  max-width: 800px;
}

@media screen and (max-width: 767px) {
  div.bew-step-box-content-text {
    font-size: 18px;
    line-height: 21px;
    padding: 18px 20px;
    max-width: 790px;
  }
}
