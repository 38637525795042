$fa-font-path: '@fortawesome/fontawesome-free/webfonts';
@import '@fortawesome/fontawesome-free/scss/regular';
@import '@fortawesome/fontawesome-free/scss/solid';
@import '@fortawesome/fontawesome-free/scss/fontawesome';

@import 'bew/styles/prelude';
@import 'bew/styles/table';

html,
body {
  height: 100%;
  margin: 0;
}

.steps-indicator {
  display: none;
}

@media print {
  .bew-simple-header {
    display: none !important;
  }

  div.main-component-left-view {
    display: none !important;
  }

  app-use-case-finished-navigation {
    display: none !important;
  }
}

.efo-button {
  margin: 1px;
  padding: 10px 15px;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

/* Cursor style when hovering over draggable items */
.efo-drag-drop-element[cdkDrag] {
  cursor: grab; /* 'grab' cursor indicates that an item can be grabbed */
}

/* Cursor style when dragging the items */
.efo-drag-drop-element[cdkDrag]:active {
  cursor: grabbing; /* 'grabbing' cursor indicates that an item is being dragged */
}

/* Ensure the cursor persists during the drag operation */
.efo-drag-drop-element.cdk-drag-preview {
  cursor: grabbing !important; /* Important to override any other cursor styles */
}

/* Define color styles for each button */
.green {
  background-color: green;
}

.green:disabled {
  background-color: lightgreen;
  cursor: default;
}

.blue {
  background-color: blue;
}

.blue:disabled {
  background-color: lightskyblue;
  cursor: default;
}

.orange {
  background-color: orange;
}

.red {
  background-color: red;
}

.red:disabled {
  background-color: indianred;
  cursor: default;
}

.button:hover {
  opacity: 0.8;
}

.efo-mulitselect {
  overflow: hidden;
  border: solid black 1px;
  border-radius: 0px;
}

.efo-error-message {
  margin-top: 4px;
  padding-top: 2px;
  padding-bottom: 2px;
  border: 2px solid red;
  color: red;
}

.input-row {
  margin-top: 20px;
}

.tooltip-inner {
  max-width: 500px !important; //define whatever width you want
  background-color: wheat;
  color: black;
  text-align: left;
}

input[type='checkbox'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 26px; /* Set the width of the checkbox */
  height: 26px; /* Set the height of the checkbox */
  border: 1px solid #000; /* Set the border color and width */
  display: inline-block;
  position: relative;
  cursor: pointer;
  margin-top: 2px;
}

input[type='checkbox']:checked::before {
  content: '';
  display: block;
  width: 10px; /* Set the width of the inner square */
  height: 10px; /* Set the height of the inner square */
  background-color: #000; /* Set the background color of the inner square */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.main-box > .bew-box {
  border: 2px solid rgba(112, 112, 112, 1) !important;
}

.main-box > .bew-box > .bew-box-title {
  font-weight: 500 !important;
}

.efo-info-box {
  font-size: 15px;
  line-height: 17px;
  color: #0d6efd;
  width: 100%;
  border: 2px solid #0d6efd;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.efo-error-box {
  font-size: 15px;
  line-height: 17px;
  color: rgba(234, 22, 31, 1);
  width: 100%;
  border: 2px solid rgba(234, 22, 31, 1);
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.pruefung-notes-toggle-button-container {
  text-align: right;
}

.pruefung-notes-container-parent {
  position: relative;
}

.pruefung-notes-container {
  position: absolute;
  bottom: 0;
  box-sizing: border-box;
  width: 100%;
}
