@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: url('../../fonts/roboto-v20-latin-100.woff') format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('../../fonts/roboto-v20-latin-300.woff') format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/roboto-v20-latin-regular.woff') format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('../../fonts/roboto-v20-latin-500.woff') format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('../../fonts/roboto-v20-latin-700.woff') format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  src: url('../../fonts/roboto-v20-latin-300italic.woff') format('woff');
}
