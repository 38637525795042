// Style-guide: Note, there's no such element called 'sand-box'. But there are various things - such
// as "teaser sand" and "hero-area" that use this style.

div.bew-sand-box {
  background-color: rgba(247, 233, 210, 1);
  -webkit-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
  margin-bottom: 20px;
  padding: 25px;
  max-width: 800px;
  height: auto;
}

@media screen and (max-width: 767px) {
  div.bew-sand-box {
    width: 100%;
  }
}

div.bew-sand-box:hover,
div.bew-sand-box:focus {
  background-color: rgba(242, 224, 195, 1);
}
