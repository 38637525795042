// Style-guide: Does not exist like this, it's called 'notice' and additionally a div (with negative margin).

div.bew-form-notice {
  margin-top: -8px;
  display: block;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 125%;
  color: rgba(0, 0, 0, 1);
  background-color: rgba(255, 255, 255, 1);
  margin-bottom: 10px;
  padding: 0;
}
