body {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 300;
  // See styleguide: Body-Text Size
  font-size: 16px;
  line-height: 26px;
}

// See styleguide: Body-Text

p {
  color: rgba(0, 0, 0, 1);
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 300;
  margin-bottom: 15px;
  margin-top: -2px;
  max-width: 800px;
  font-size: 16px;
  line-height: 26px;
}

@media screen and (max-width: 767px) {
  p {
    font-size: 15px;
    line-height: 24px;
  }
}

// See styleguide: H1

h1 {
  color: rgba(0, 0, 0, 1);
  font-style: normal;
  font-weight: 100;
  max-width: 800px;
  font-size: 54px;
  line-height: 62px;
  letter-spacing: 0.01rem;
  word-spacing: 0.025rem;
  margin: 0 0 44px -2px;
}

@media screen and (max-width: 1119px) {
  h1 {
    font-size: 48px;
    line-height: 56px;
    margin: -9px 0 28px -1px;
  }
}

@media screen and (max-width: 767px) {
  h1 {
    font-size: 36px;
    line-height: 42px;
    font-style: normal;
    font-weight: 300;
    margin: -6px 0 24px -1px;
  }
}

// See styleguide: H2

h2 {
  font-size: 30px;
  line-height: 36px;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0.01rem;
  word-spacing: 0.025rem;
  max-width: 800px;
  color: rgba(0, 0, 0, 1);
  margin: 0 0 12px -2px;
}

@media screen and (max-width: 1119px) {
  h2 {
    font-size: 30px;
    line-height: 35px;
    margin: -5px 0 10px -1.5px;
  }
}

@media screen and (max-width: 767px) {
  h2 {
    font-size: 24px;
    line-height: 29px;
    margin: -4px 0 8px -1px;
  }
}

// See styleguide: H3

h3 {
  font-size: 26px;
  line-height: 30px;
  max-width: 800px;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0.01rem;
  word-spacing: 0.025rem;
  color: rgba(0, 0, 0, 1);
  margin: 0 0 12px -1px;
}

@media screen and (max-width: 1119px) {
  h3 {
    font-weight: 300;
    font-size: 24px;
    line-height: 28px;
    margin: -3px 0 12px -1px;
  }
}

@media screen and (max-width: 767px) {
  h3 {
    font-weight: 300;
    font-size: 21px;
    line-height: 25px;
    margin: -2px 0 12px 0px;
  }
}

// See styleguide: H4

h4 {
  margin: 0 0 12px -1px;
  max-width: 800px;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0.01rem;
  word-spacing: 0.025rem;
  color: rgba(0, 0, 0, 1);
  font-size: 24px;
  line-height: 28px;
}

@media screen and (max-width: 1119px) {
  h4 {
  }
}

@media screen and (max-width: 767px) {
  h4 {
    font-size: 21px;
    line-height: 25px;
  }
}

// See styleguide: H5

h5 {
  font-size: 21px;
  line-height: 25px;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0.01rem;
  word-spacing: 0.025rem;
  color: rgba(0, 0, 0, 1);
}

@media screen and (max-width: 1119px) {
  h5 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media screen and (max-width: 767px) {
  h5 {
    font-size: 18px;
    line-height: 21px;
  }
}

// See styleguide: H6 Size (H6 is missing, took values from H5)

h6 {
  font-size: 18px;
  line-height: 21px;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0.01rem;
  word-spacing: 0.025rem;
  color: rgba(0, 0, 0, 1);
  margin: 0;
}

@media screen and (max-width: 1119px) {
  h6 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media screen and (max-width: 767px) {
  h6 {
    font-size: 16px;
    line-height: 21px;
  }
}

// See styleguide: Text-Link in Text

a {
  text-decoration: underline;
  font-style: normal;
  font-weight: 300;
  color: rgba(0, 0, 0, 1);
}

a:hover,
a:focus {
  text-decoration: underline;
  text-decoration-color: #ea161f;
  color: rgba(0, 0, 0, 1);
}
