// See Style-Sheet: Splitter link list

a.bew-splitter {
  position: relative;
  color: rgba(0, 0, 0, 1);
  font-family: 'Roboto', serif;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 125%;
  text-decoration: none;
  display: inline-block;
  border-bottom: 1px solid rgba(255, 255, 255, 1);
  margin-top: 14px;
  margin-bottom: 14px;
  margin-left: 42px;
  padding-bottom: 2px;
}

@media screen and (max-width: 767px) {
  a.bew-splitter {
    font-size: 16px;
    line-height: 125%;
    margin-left: 38px;
  }
}

a.bew-splitter::before {
  background-image: url(../../assets/bew-go-next.svg);
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: auto;
  width: 24px;
  height: 20px;
  content: ' ';
  position: absolute;
  left: -42px;
  top: 1px;
  -webkit-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
}

a.bew-splitter:hover::before,
a.bew-splitter:focus::before,
a.bew-splitter:active::before {
  -webkit-transform: scale(1.5);
  -ms-transform: scale(1.5);
  transform: scale(1.5);
}

a.bew-splitter > div {
  font-style: normal;
  font-weight: 300;
  max-width: 800px;
  font-size: 16px;
  line-height: 125%;
  opacity: 75%;
}
