// See styleguide: List
// note: In the styleguide the list has the class 'list' (I think that's not required, since there's no other list
// and this is the primary list).

ul {
  color: rgba(0, 0, 0, 1);
  line-height: 26px;
}

@media screen and (max-width: 767px) {
  ul {
    font-size: 15px;
    line-height: 24px;
  }
}

ul > li {
  list-style: square;
}

ul > li::marker {
  color: #ea161f;
}
