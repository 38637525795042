// Bew table: See "Table (default)" and "Table (with big numbers)" in the styleguide.

div.bew-table-container {
  overflow-x: auto;
  overflow-y: hidden;
  //max-width: 800px;
}

div.bew-table-container table {
  width: 100%;
  margin-bottom: 15px;
  //max-width: 800px;
  border-collapse: collapse;
  border-spacing: 0;
}

div.bew-table-container tbody {
  border: none !important;
}

div.bew-table-container tbody,
div.bew-table-container tfoot,
div.bew-table-container thead {
  background-color: #fefefe;
}

div.bew-table-container tr {
  font-style: normal;
  font-weight: 300;
  color: rgba(0, 0, 0, 1);
}

div.bew-table-container th {
  vertical-align: bottom;
  text-align: left;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 125%;
  border-bottom: 1px solid rgba(0, 0, 0, 1);
  padding: 13px 15px !important;
  max-width: 800px !important;
  white-space: nowrap;
}

div.bew-table-container tbody tr:nth-child(even) {
  border-bottom: 0;
}

div.bew-table-container td {
  vertical-align: top;
  text-align: left;
  color: rgba(0, 0, 0, 1);
  font-size: 14px;
  line-height: 18px;
  border-bottom: 1px solid rgba(182, 182, 182, 1);
  padding: 13px 15px !important;
  max-width: 800px !important;
}

div.bew-table-container tr:hover,
div.bew-table-container tr:focus {
  background-color: rgba(252, 248, 243, 1) !important;
}

// Use this style for the title row. See "Table (with big numbers)" in the styleguide.
div.bew-table-container td.bew-table-title {
  color: rgba(112, 112, 112, 1);
  font-size: 14px;
  line-height: 18px;
  font-style: normal;
  font-weight: 400;
  padding: 13px 15px !important;
  vertical-align: middle;
  max-width: 253px;
  word-break: break-word;
}

// A caption (to be placed outside the container)
div.bew-table-caption {
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin: 8px 0 42px;
  max-width: 470px;
  width: calc(100% - 100px);
}
