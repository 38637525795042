// Style-guide: See Grey line.
// Style-guide: The style-guide uses a <div> with a style called 'hr'. I don't know why they're doing this - since there's a <hr> tag.

hr {
  background-color: rgba(112, 112, 112, 0.5);
  margin: 30px 45px;
  height: 1px;
  border: 0;
}

@media screen and (max-width: 1119px) {
  hr {
    margin: 20px 30px;
  }
}

@media screen and (max-width: 767px) {
  hr {
    margin: 10px 20px;
  }
}
